import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Dashboard from "./pages/dashboard";
import Courses from './pages/courses';
import "./pages/style_main1.css"


function App()
{
  return (
    <>


      <BrowserRouter>
        {/* <Routes>
        <Route path="/" element={<HomePage />}>
          <Route path="home" element={<HomePage />} />
          <Route path="number" element={<Namecalculator />} />
          <Route path="*" element={<Pagenotfound />} />
        </Route>
      </Routes> */}

        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/home" element={<Dashboard />} />
          <Route path="/courses" element={<Courses />} />
          {/* <Route path="/emicalculator" element={<EmiCalcualtor />} />
          <Route path="/termsofuse" element={<TermsofUse />} /> */}

        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
