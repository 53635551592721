
// import Navigation from "../controls/navigation";
import React, { useState, useEffect } from "react";
import TopMenu from "./pagecontrols";




export default function Dashboard()
{

    function changePageShow()
    { }


    function Menu1()
    {
        return (
            <>
                <a href="#Home">Home</a>
                <a href="#About">About</a>
                <a href="#Courses">Courses</a>
                <a href="#YouTube">YouTube</a>
                <a href="#Reviews">Reviews</a>
                <a href="#Contact">Contact</a>

            </>
        );
    }



    return (
        <>


            <div className="ob_menu">
                <Menu1 />

            </div>

            {/* <div className="sapcetop"></div> */}


            {/* <div className="ob_body2">
               
                <Mainpage />
                <Page_About />
                <Page_Courses />
                <Page_YT />
                <Page_Review />
                <Page_Contact />
                <Page_footer />
            </div> */}


            <Mainpage />
            <div className="box0"> <Page_About /></div>
            <div className="box0"> <Page_Courses /></div>
            <div className="box0"> <Page_JoinNow /></div>
            <div className="box0"> <Page_Review /></div>
            <div className="box0"> <Page_YT /></div>

            <div className="box0"> <Page_Contact /></div>
            <div className="box0"> <Page_footer /></div>










        </>
    );
}


export function Dashboard2()
{
    const [pagename, setpagename] = useState(<Mainpage />);



    function changePageShow(name)
    {
        console.log("called", name)
        setpagename(name)
    }




    function Menu1()
    {
        return (
            <>



                <button onClick={() => changePageShow(<Mainpage />)} >
                    Home
                </button>


                <button onClick={() => changePageShow(<Page_YT />)} >
                    Courses
                </button>

                <button onClick={() => changePageShow(<Page_YT />)} >
                    YouTube
                </button>

                <button onClick={() => changePageShow(<Page_Review />)} >
                    Reviews
                </button>

                <button onClick={() => changePageShow(<Page_Contact />)} >
                    Contact
                </button>






            </>
        );
    }



    return (
        <>

            <div className="outbox">

                <div className="ob_menu">
                    <Menu1 />

                </div>
                <div className="ob_body">
                    {pagename}

                </div>

            </div>

        </>
    );
}


function Mainpage()
{
    return (
        <>

            <div className="box2_100">
                {/* <div id="Home" className="scroll_title"></div> */}

                <div className="mainbox">
                    <img src="images/mainpic1.png"></img>
                </div>

            </div>

        </>
    );
}



function Page_JoinNow()
{
    return (
        <>

            <div className="box2">
                <div id="Contact" className="scroll_title"></div>
                <div id="" className="section_title">Join Now</div>


                <p className="contact_subtext">
                    To know about our up coming batches do join our Whatsapp group. <br />For new courses register online</p>
                <p className="contact_subtext"></p>

                <div className="space30"></div>

                <div className="item_row_row">


                    <a className="btn_link btncolor_green2" href="https://chat.whatsapp.com/EKcEA3kKOc4JK8YtHy1QPD" target="_blank" >
                        Join Whatsapp Group
                    </a>

                    <a className="btn_link btncolor_blue2" href="https://www.youtube.com/@dharmanumerology" target="_blank" >
                        Register Now
                    </a>

                </div>





            </div>

        </>
    );
}


function Page_YT()
{
    return (
        <>

            <div className="box2">
                <div id="YouTube" className="scroll_title"></div>
                <div id="" className="section_title">YouTube</div>

                <div className="item_row_row">


                    <div className="ytbox">
                        <iframe
                            src="https://www.youtube.com/embed/M_NFcFE8ux8">
                        </iframe>

                    </div>

                    <div className="ytbox">
                        <iframe
                            src="https://www.youtube.com/embed/sR9OUMCnyBE">
                        </iframe>

                    </div>

                    <div className="ytbox">
                        <iframe
                            src="https://www.youtube.com/embed/TX3XhFF1b_Y">
                        </iframe>

                    </div>

                    <div className="ytbox">
                        <iframe
                            src="https://www.youtube.com/embed/sR9OUMCnyBE">
                        </iframe>

                    </div>

                    <div className="ytbox">
                        <iframe
                            src="https://www.youtube.com/embed/9pDIFsBUUqc">
                        </iframe>

                    </div>

                    <div className="ytbox">
                        <iframe
                            src="https://www.youtube.com/embed/sR9OUMCnyBE">
                        </iframe>

                    </div>




                </div>


                <div className="item_row_row">

                    <a className="btn_link btncolor_red" href="https://www.youtube.com/@VASTOOSEVOK/videos" target="_blank" >
                        Show YouTube Channel
                    </a>

                </div>


            </div>

        </>
    );
}


function Page_Review()
{
    return (
        <>

            <div className="box2">
                <div id="Reviews" className="scroll_title"></div>
                <div id="" className="section_title">Reviews</div>




                <div className="item_row_row">
                    {/* G:\0_Development_June2022\DevSir_Website1\devsirapp\src\images\Picture2.png
                    G:\0_Development_June2022\DevSir_Website1\devsirapp\src\images\review1.png */}

                    <div className="revwbox">
                        <img src="images/review1.png"></img>
                    </div>

                    <div className="revwbox">
                        <img src="images/review2.png"></img>
                    </div>

                    <div className="revwbox">
                        <img src="images/review4.png"></img>
                    </div>

                    <div className="revwbox">
                        <img src="images/review3.png"></img>
                    </div>

                </div>

                <div className="item_row_row">

                    <a className="btn_link btncolor_blue" href="https://www.facebook.com/groups/1375284593114222" target="_blank" >
                        Read on facebook
                    </a>


                    <a className="btn_link btncolor_green" href="https://www.google.com/maps/place/Dharma+Numerology/@20.1636731,72.7668628,17z/data=!4m8!3m7!1s0x3be72b3e3c10c01f:0x720bd4f2d184c86!8m2!3d20.1636731!4d72.7668628!9m1!1b1!16s%2Fg%2F11s37fc88g?entry=ttu" target="_blank" >
                        Read on Google
                    </a>

                </div>



            </div>

        </>
    );
}



function Page_Contact()
{
    return (
        <>

            <div className="box2">
                <div id="Contact" className="scroll_title"></div>
                <div id="" className="section_title">Connect with me</div>


                <p className="contact_subtext">+91 9152191257, +91 7733919291</p>
                <p className="contact_subtext">abc1233@gmail.com</p>

                <div className="space30"></div>

                <div className="item_row_row">


                    <a className="btn_link btncolor_light" href="https://chat.whatsapp.com/EKcEA3kKOc4JK8YtHy1QPD" target="_blank" >
                        Whatsapp
                    </a>

                    <a className="btn_link btncolor_light" href="https://www.youtube.com/@dharmanumerology" target="_blank" >
                        YouTube
                    </a>

                    <a className="btn_link btncolor_light" href="https://www.facebook.com/groups/1375284593114222" target="_blank" >
                        facebook
                    </a>

                    <a className="btn_link btncolor_light" href="https://www.google.com/maps/place/Dharma+Numerology/@20.1636731,72.7668628,17z/data=!4m8!3m7!1s0x3be72b3e3c10c01f:0x720bd4f2d184c86!8m2!3d20.1636731!4d72.7668628!9m1!1b1!16s%2Fg%2F11s37fc88g?entry=ttu" target="_blank" >
                        Google
                    </a>

                    <a className="btn_link btncolor_light" href="https://www.google.com/maps/place/Dharma+Numerology/@20.1636731,72.7668628,17z/data=!4m8!3m7!1s0x3be72b3e3c10c01f:0x720bd4f2d184c86!8m2!3d20.1636731!4d72.7668628!9m1!1b1!16s%2Fg%2F11s37fc88g?entry=ttu" target="_blank" >
                        Instagram
                    </a>

                    <a className="btn_link btncolor_light" href="https://t.me/dharmanumerology" target="_blank" >
                        Telegram
                    </a>



                </div>





            </div>

        </>
    );
}



function Page_footer()
{
    return (
        <>

            <div className="box2_100 color_black">
                <div className="footer">
                    <p className="subtext_white">
                        © 2024 Dharma Numerology
                    </p>
                </div>
            </div>

        </>
    );
}



function Page_Courses()
{
    return (
        <>

            <div className="box2">
                <div id="Courses" className="scroll_title"></div>
                <div id="" className="section_title">Courses</div>

                <div className="item_row_row">
                    <div className="coursebox">
                        <img src="images/course_numero1.png"></img>
                        <a href="/courses#advancenumero">Advance Numerology</a>

                    </div>

                    <div className="coursebox">
                        <img src="images/course_mobilenumero.png"></img>
                        <a href="/courses#mobnumero">Mobile Numerology</a>
                    </div>

                    <div className="coursebox">
                        <img src="images/course_homevastu1.png"></img>
                        <a href="/courses#homevastu">Home Vastu</a>
                    </div>

                    <div className="coursebox">
                        <img src="images/course_bodyvastu1.png"></img>
                        <a href="/courses#bodyvastu">Body Vastu</a>
                    </div>


                </div>

            </div>

        </>
    );
}



function Page_About()
{
    return (
        <>

            <div className="box2">
                <div id="About" className="scroll_title"></div>
                <div id="" className="section_title">About</div>

                <p className="subtext">You should mention whether you want it to smoothly scroll or simply jump to an element. Jumping is easy & can be done just with HTML or Javascript. The simplest is to use anchor's. The limitation is that every element you want to scroll to has to have an id. A side effect is that #theID will be appended to the URL</p>

            </div>

        </>
    );
}
