
// import Navigation from "../controls/navigation";
import React, { useState, useEffect } from "react";
import TopMenu from "./pagecontrols";




export default function Courses()
{

    function changePageShow()
    { }


    function Menu1()
    {
        return (
            <>
                <a href="/home">Home</a>
                <a href="#advancenumero">Advance Numerology</a>
                <a href="#mobnumero">Mobile Numerology</a>
                <a href="#homevastu">Home Vastu</a>
                <a href="#bodyvastu">Body Vastu</a>


            </>
        );
    }



    return (
        <>


            <div className="ob_menu">
                <Menu1 />

            </div>

            {/* <div className="sapcetop"></div> */}


            {/* <div className="ob_body2">
                <Course_numero />
                <Course_mobnumero />
                <Course_homevastu />
                <Course_bodyvastu />
                <div className="box0"> <Page_footer /></div>
            </div > */}


            <div className="box05"> <Course_numero /></div>
            <div className="box05"> <Course_mobnumero /></div>
            <div className="box05"> <Course_homevastu /></div>
            <div className="box05"> <Course_bodyvastu /></div>
            <div className="box05"> <Page_footer /></div>




        </>
    );
}






function Course_numero()
{
    return (
        <>

            <div className="box5">
                <div id="advancenumero" className="scroll_title"></div>

                <div className="coursedetails">


                    <p className="title">Advance Numerology</p>

                    <img src="images/course_numero1.png"></img>

                    <div className="space30"></div>


                    <p className="detailsTxt">
                        About this item
                        ⦁L-rods are useful for any type of dowsing, A dowsing rod, also known as a divining rod, . The rod is typically made of metal and is shaped like an "L".
                        ⦁L-rods respond by either opening or closing when asked a question, many people who practice dowsing swear by its efficacy and believe that it works for them.
                        ⦁According to Energy Expert, Metal Dowsing Rod Pair is primarily used for Dowsing Reiki Healing, Feng Shui, Vastu, Crystal Healing, Vastu Correction, Energy Check & Aura Cleansing.
                        ⦁According to Energy Expert, It gives a great sense of Spiritual Awakening, Mental Peace, Psychic Horizon, Magical and Divine they can be used in many different methods of healing. Removes all bad energy, evil forces, negativity and wrong choice. Used for, looking for lost items, ghost hunting, underground water and Metal, auras, energy and much more.
                        ⦁According to Expert, L-Dowsing Rods Benefits are Enhances, awareness, visualization, intuition, psychic abilities. Enhances happier moods. Clear negative entities. Helps reach higher vibration of energies. Some people use it as a form of divination or to connect with their intuition, while others simply enjoy the challenge of trying to locate hidden objects.
                        ⦁Kindly Consult an Expert before Purchasing the Products.
                        About this item
                        ⦁L-rods are useful for any type of dowsing, A dowsing rod, also known as a divining rod, . The rod is typically made of metal and is shaped like an "L".
                        ⦁L-rods respond by either opening or closing when asked a question, many people who practice dowsing swear by its efficacy and believe that it works for them.
                        ⦁According to Energy Expert, Metal Dowsing Rod Pair is primarily used for Dowsing Reiki Healing, Feng Shui, Vastu, Crystal Healing, Vastu Correction, Energy Check & Aura Cleansing.
                        ⦁According to Energy Expert, It gives a great sense of Spiritual Awakening, Mental Peace, Psychic Horizon, Magical and Divine they can be used in many different methods of healing. Removes all bad energy, evil forces, negativity and wrong choice. Used for, looking for lost items, ghost hunting, underground water and Metal, auras, energy and much more.
                        ⦁According to Expert, L-Dowsing Rods Benefits are Enhances, awareness, visualization, intuition, psychic abilities. Enhances happier moods. Clear negative entities. Helps reach higher vibration of energies. Some people use it as a form of divination or to connect with their intuition, while others simply enjoy the challenge of trying to locate hidden objects.
                        ⦁Kindly Consult an Expert before Purchasing the Products.
                        About this item
                        ⦁L-rods are useful for any type of dowsing, A dowsing rod, also known as a divining rod, . The rod is typically made of metal and is shaped like an "L".
                        ⦁L-rods respond by either opening or closing when asked a question, many people who practice dowsing swear by its efficacy and believe that it works for them.
                        ⦁According to Energy Expert, Metal Dowsing Rod Pair is primarily used for Dowsing Reiki Healing, Feng Shui, Vastu, Crystal Healing, Vastu Correction, Energy Check & Aura Cleansing.
                        ⦁According to Energy Expert, It gives a great sense of Spiritual Awakening, Mental Peace, Psychic Horizon, Magical and Divine they can be used in many different methods of healing. Removes all bad energy, evil forces, negativity and wrong choice. Used for, looking for lost items, ghost hunting, underground water and Metal, auras, energy and much more.
                        ⦁According to Expert, L-Dowsing Rods Benefits are Enhances, awareness, visualization, intuition, psychic abilities. Enhances happier moods. Clear negative entities. Helps reach higher vibration of energies. Some people use it as a form of divination or to connect with their intuition, while others simply enjoy the challenge of trying to locate hidden objects.
                        ⦁Kindly Consult an Expert before Purchasing the Products.


                    </p>


                </div>


            </div>

        </>
    );
}




function Course_mobnumero()
{
    return (
        <>

            <div className="box5">
                <div id="mobnumero" className="scroll_title"></div>

                <div className="coursedetails">


                    <p className="title">Mobile Numerology</p>

                    <img src="images/course_mobilenumero.png"></img>

                    <div className="space30"></div>


                    <p className="detailsTxt">
                        About this item
                        ⦁L-rods are useful for any type of dowsing, A dowsing rod, also known as a divining rod, . The rod is typically made of metal and is shaped like an "L".
                        ⦁L-rods respond by either opening or closing when asked a question, many people who practice dowsing swear by its efficacy and believe that it works for them.
                        ⦁According to Energy Expert, Metal Dowsing Rod Pair is primarily used for Dowsing Reiki Healing, Feng Shui, Vastu, Crystal Healing, Vastu Correction, Energy Check & Aura Cleansing.
                        ⦁According to Energy Expert, It gives a great sense of Spiritual Awakening, Mental Peace, Psychic Horizon, Magical and Divine they can be used in many different methods of healing. Removes all bad energy, evil forces, negativity and wrong choice. Used for, looking for lost items, ghost hunting, underground water and Metal, auras, energy and much more.
                        ⦁According to Expert, L-Dowsing Rods Benefits are Enhances, awareness, visualization, intuition, psychic abilities. Enhances happier moods. Clear negative entities. Helps reach higher vibration of energies. Some people use it as a form of divination or to connect with their intuition, while others simply enjoy the challenge of trying to locate hidden objects.
                        ⦁Kindly Consult an Expert before Purchasing the Products.
                        About this item
                        ⦁L-rods are useful for any type of dowsing, A dowsing rod, also known as a divining rod, . The rod is typically made of metal and is shaped like an "L".
                        ⦁L-rods respond by either opening or closing when asked a question, many people who practice dowsing swear by its efficacy and believe that it works for them.
                        ⦁According to Energy Expert, Metal Dowsing Rod Pair is primarily used for Dowsing Reiki Healing, Feng Shui, Vastu, Crystal Healing, Vastu Correction, Energy Check & Aura Cleansing.
                        ⦁According to Energy Expert, It gives a great sense of Spiritual Awakening, Mental Peace, Psychic Horizon, Magical and Divine they can be used in many different methods of healing. Removes all bad energy, evil forces, negativity and wrong choice. Used for, looking for lost items, ghost hunting, underground water and Metal, auras, energy and much more.
                        ⦁According to Expert, L-Dowsing Rods Benefits are Enhances, awareness, visualization, intuition, psychic abilities. Enhances happier moods. Clear negative entities. Helps reach higher vibration of energies. Some people use it as a form of divination or to connect with their intuition, while others simply enjoy the challenge of trying to locate hidden objects.
                        ⦁Kindly Consult an Expert before Purchasing the Products.
                        About this item
                        ⦁L-rods are useful for any type of dowsing, A dowsing rod, also known as a divining rod, . The rod is typically made of metal and is shaped like an "L".
                        ⦁L-rods respond by either opening or closing when asked a question, many people who practice dowsing swear by its efficacy and believe that it works for them.
                        ⦁According to Energy Expert, Metal Dowsing Rod Pair is primarily used for Dowsing Reiki Healing, Feng Shui, Vastu, Crystal Healing, Vastu Correction, Energy Check & Aura Cleansing.
                        ⦁According to Energy Expert, It gives a great sense of Spiritual Awakening, Mental Peace, Psychic Horizon, Magical and Divine they can be used in many different methods of healing. Removes all bad energy, evil forces, negativity and wrong choice. Used for, looking for lost items, ghost hunting, underground water and Metal, auras, energy and much more.
                        ⦁According to Expert, L-Dowsing Rods Benefits are Enhances, awareness, visualization, intuition, psychic abilities. Enhances happier moods. Clear negative entities. Helps reach higher vibration of energies. Some people use it as a form of divination or to connect with their intuition, while others simply enjoy the challenge of trying to locate hidden objects.
                        ⦁Kindly Consult an Expert before Purchasing the Products.


                    </p>

                </div>


            </div>

        </>
    );
}




function Course_homevastu()
{
    return (
        <>

            <div className="box5">
                <div id="homevastu" className="scroll_title"></div>

                <div className="coursedetails">


                    <p className="title">Home Vastu</p>

                    <img src="images/course_homevastu1.png"></img>

                    <div className="space30"></div>


                    <p className="detailsTxt">
                        About this item
                        ⦁L-rods are useful for any type of dowsing, A dowsing rod, also known as a divining rod, . The rod is typically made of metal and is shaped like an "L".
                        ⦁L-rods respond by either opening or closing when asked a question, many people who practice dowsing swear by its efficacy and believe that it works for them.
                        ⦁According to Energy Expert, Metal Dowsing Rod Pair is primarily used for Dowsing Reiki Healing, Feng Shui, Vastu, Crystal Healing, Vastu Correction, Energy Check & Aura Cleansing.
                        ⦁According to Energy Expert, It gives a great sense of Spiritual Awakening, Mental Peace, Psychic Horizon, Magical and Divine they can be used in many different methods of healing. Removes all bad energy, evil forces, negativity and wrong choice. Used for, looking for lost items, ghost hunting, underground water and Metal, auras, energy and much more.
                        ⦁According to Expert, L-Dowsing Rods Benefits are Enhances, awareness, visualization, intuition, psychic abilities. Enhances happier moods. Clear negative entities. Helps reach higher vibration of energies. Some people use it as a form of divination or to connect with their intuition, while others simply enjoy the challenge of trying to locate hidden objects.
                        ⦁Kindly Consult an Expert before Purchasing the Products.
                        About this item
                        ⦁L-rods are useful for any type of dowsing, A dowsing rod, also known as a divining rod, . The rod is typically made of metal and is shaped like an "L".
                        ⦁L-rods respond by either opening or closing when asked a question, many people who practice dowsing swear by its efficacy and believe that it works for them.
                        ⦁According to Energy Expert, Metal Dowsing Rod Pair is primarily used for Dowsing Reiki Healing, Feng Shui, Vastu, Crystal Healing, Vastu Correction, Energy Check & Aura Cleansing.
                        ⦁According to Energy Expert, It gives a great sense of Spiritual Awakening, Mental Peace, Psychic Horizon, Magical and Divine they can be used in many different methods of healing. Removes all bad energy, evil forces, negativity and wrong choice. Used for, looking for lost items, ghost hunting, underground water and Metal, auras, energy and much more.
                        ⦁According to Expert, L-Dowsing Rods Benefits are Enhances, awareness, visualization, intuition, psychic abilities. Enhances happier moods. Clear negative entities. Helps reach higher vibration of energies. Some people use it as a form of divination or to connect with their intuition, while others simply enjoy the challenge of trying to locate hidden objects.
                        ⦁Kindly Consult an Expert before Purchasing the Products.
                        About this item
                        ⦁L-rods are useful for any type of dowsing, A dowsing rod, also known as a divining rod, . The rod is typically made of metal and is shaped like an "L".
                        ⦁L-rods respond by either opening or closing when asked a question, many people who practice dowsing swear by its efficacy and believe that it works for them.
                        ⦁According to Energy Expert, Metal Dowsing Rod Pair is primarily used for Dowsing Reiki Healing, Feng Shui, Vastu, Crystal Healing, Vastu Correction, Energy Check & Aura Cleansing.
                        ⦁According to Energy Expert, It gives a great sense of Spiritual Awakening, Mental Peace, Psychic Horizon, Magical and Divine they can be used in many different methods of healing. Removes all bad energy, evil forces, negativity and wrong choice. Used for, looking for lost items, ghost hunting, underground water and Metal, auras, energy and much more.
                        ⦁According to Expert, L-Dowsing Rods Benefits are Enhances, awareness, visualization, intuition, psychic abilities. Enhances happier moods. Clear negative entities. Helps reach higher vibration of energies. Some people use it as a form of divination or to connect with their intuition, while others simply enjoy the challenge of trying to locate hidden objects.
                        ⦁Kindly Consult an Expert before Purchasing the Products.


                    </p>


                </div>


            </div>

        </>
    );
}





function Course_bodyvastu()
{
    return (
        <>

            <div className="box5">
                <div id="bodyvastu" className="scroll_title"></div>

                <div className="coursedetails">


                    <p className="title">Body Vastu</p>

                    <img src="images/course_bodyvastu1.png"></img>

                    <div className="space30"></div>


                    <p className="detailsTxt">
                        About this item
                        ⦁L-rods are useful for any type of dowsing, A dowsing rod, also known as a divining rod, . The rod is typically made of metal and is shaped like an "L".
                        ⦁L-rods respond by either opening or closing when asked a question, many people who practice dowsing swear by its efficacy and believe that it works for them.
                        ⦁According to Energy Expert, Metal Dowsing Rod Pair is primarily used for Dowsing Reiki Healing, Feng Shui, Vastu, Crystal Healing, Vastu Correction, Energy Check & Aura Cleansing.
                        ⦁According to Energy Expert, It gives a great sense of Spiritual Awakening, Mental Peace, Psychic Horizon, Magical and Divine they can be used in many different methods of healing. Removes all bad energy, evil forces, negativity and wrong choice. Used for, looking for lost items, ghost hunting, underground water and Metal, auras, energy and much more.
                        ⦁According to Expert, L-Dowsing Rods Benefits are Enhances, awareness, visualization, intuition, psychic abilities. Enhances happier moods. Clear negative entities. Helps reach higher vibration of energies. Some people use it as a form of divination or to connect with their intuition, while others simply enjoy the challenge of trying to locate hidden objects.
                        ⦁Kindly Consult an Expert before Purchasing the Products.
                        About this item
                        ⦁L-rods are useful for any type of dowsing, A dowsing rod, also known as a divining rod, . The rod is typically made of metal and is shaped like an "L".
                        ⦁L-rods respond by either opening or closing when asked a question, many people who practice dowsing swear by its efficacy and believe that it works for them.
                        ⦁According to Energy Expert, Metal Dowsing Rod Pair is primarily used for Dowsing Reiki Healing, Feng Shui, Vastu, Crystal Healing, Vastu Correction, Energy Check & Aura Cleansing.
                        ⦁According to Energy Expert, It gives a great sense of Spiritual Awakening, Mental Peace, Psychic Horizon, Magical and Divine they can be used in many different methods of healing. Removes all bad energy, evil forces, negativity and wrong choice. Used for, looking for lost items, ghost hunting, underground water and Metal, auras, energy and much more.
                        ⦁According to Expert, L-Dowsing Rods Benefits are Enhances, awareness, visualization, intuition, psychic abilities. Enhances happier moods. Clear negative entities. Helps reach higher vibration of energies. Some people use it as a form of divination or to connect with their intuition, while others simply enjoy the challenge of trying to locate hidden objects.
                        ⦁Kindly Consult an Expert before Purchasing the Products.
                        About this item
                        ⦁L-rods are useful for any type of dowsing, A dowsing rod, also known as a divining rod, . The rod is typically made of metal and is shaped like an "L".
                        ⦁L-rods respond by either opening or closing when asked a question, many people who practice dowsing swear by its efficacy and believe that it works for them.
                        ⦁According to Energy Expert, Metal Dowsing Rod Pair is primarily used for Dowsing Reiki Healing, Feng Shui, Vastu, Crystal Healing, Vastu Correction, Energy Check & Aura Cleansing.
                        ⦁According to Energy Expert, It gives a great sense of Spiritual Awakening, Mental Peace, Psychic Horizon, Magical and Divine they can be used in many different methods of healing. Removes all bad energy, evil forces, negativity and wrong choice. Used for, looking for lost items, ghost hunting, underground water and Metal, auras, energy and much more.
                        ⦁According to Expert, L-Dowsing Rods Benefits are Enhances, awareness, visualization, intuition, psychic abilities. Enhances happier moods. Clear negative entities. Helps reach higher vibration of energies. Some people use it as a form of divination or to connect with their intuition, while others simply enjoy the challenge of trying to locate hidden objects.
                        ⦁Kindly Consult an Expert before Purchasing the Products.


                    </p>

                </div>


            </div>

        </>
    );
}













function Page_footerXXX()
{
    return (
        <>

            <div className="box5">
                <div className="footer">
                    <p className="subtext_white">
                        © 2024 Dharma Numerology
                    </p>
                </div>
            </div>

        </>
    );
}




function Page_footer()
{
    return (
        <>

            <div className="box2_100 color_black">
                <div className="footer">
                    <p className="subtext_white">
                        © 2024 Dharma Numerology
                    </p>
                </div>
            </div>

        </>
    );
}




function Page_Courses()
{
    return (
        <>

            <div className="box5">
                <div id="Courses" className="scroll_title"></div>
                <div id="" className="section_title">Courses</div>

                <div className="item_row_row">
                    <div className="coursebox">
                        <img src="images/course_numero1.png"></img>
                        <p>Advance Numerology</p>
                    </div>

                    <div className="coursebox">
                        <img src="images/course_mobilenumero.png"></img>
                        <p>Mobile Numerology</p>
                    </div>

                    <div className="coursebox">
                        <img src="images/course_homevastu1.png"></img>
                        <p>Home Vastu</p>
                    </div>

                    <div className="coursebox">
                        <img src="images/course_bodyvastu1.png"></img>
                        <p>Body Vastu</p>
                    </div>


                </div>

            </div>

        </>
    );
}

